var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root",
    attrs: {
      "id": "app"
    }
  }, [_c('notifications', {
    staticClass: "mt-5 mr-4",
    attrs: {
      "group": "foo"
    }
  }), _c('notifications', {
    attrs: {
      "singleton": true,
      "group": "full-noti",
      "position": "bottom center",
      "width": "100%",
      "fullWidth": "true",
      "speed": 550,
      "duration": 5000,
      "max": 1
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_c('div', {
          staticClass: "vuenotiz text-center",
          class: props.item.type,
          staticStyle: {
            "width": "100%",
            "padding": "30px",
            "color": "white !important",
            "background-color": "#ff392e"
          }
        }, [_c('a', {
          staticClass: "title",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('strong', {
          staticStyle: {
            "color": "#ccc"
          }
        }, [_vm._v(_vm._s(props.item.title))])]), _c('img', {
          staticStyle: {
            "position": "absolute",
            "right": "0",
            "top": "0",
            "height": "55px",
            "padding": "15px"
          },
          attrs: {
            "src": "/images/icon/x-mark.png",
            "alt": "Close"
          },
          on: {
            "click": props.close
          }
        }), _c('div', {
          staticClass: "first-letter",
          staticStyle: {
            "color": "#fff",
            "font-size": "16pt"
          },
          domProps: {
            "innerHTML": _vm._s(props.item.text)
          }
        })])];
      }
    }])
  }), _c('router-view', {
    key: _vm.$route.fullPath
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }